<template>
  <ion-page>
    <top-header page-title="Chat & Dedicații"></top-header>
    <ion-content>
      <ion-tabs >
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="chat" @click="this.$router.replace('/tab/chat')">
            <ion-label>CHAT</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="dedicatie" @click="this.$router.replace('/tab/dedicatie')">
            <ion-label>DEDICAȚII</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import TopHeader from "../../components/menu/TopHeader";
import {
  IonPage,
  IonContent,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,

} from '@ionic/vue';

export default{
  components: {
    IonPage,
    IonContent,
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonLabel,
    TopHeader,
  },
};
</script>
